import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { combineReducers } from "redux";

// Import your reducers
import flightReducer from "./reducers/flightSlice";
import airPriceReducer from "./slices/airPriceSlice";
import searchIDResultIDReducer from "./slices/searchIDResultIDSlice";
import airPreBookReducer from "./slices/airPreBookSlice";
import passengerDetailsReducer from "./slices/passengerDetailsSlice";
import flightDataReducer from "./slices/singleFlightDataSlice";

// Combine your reducers into a root reducer
const rootReducer = combineReducers({
  flight: flightReducer,
  airPrice: airPriceReducer,
  searchIDResultID: searchIDResultIDReducer,
  airPreBook: airPreBookReducer,
  passengerDetails: passengerDetailsReducer,
  flightData: flightDataReducer,
  // Add other reducers here if needed
});

// Configure the store without persistence
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk), // Add thunk middleware
});

export default store;
