import React from "react";
import { Box, Typography, Button } from "@mui/material";

const BookingOptions = ({ handleDownloadPDF }) => {
  const boxStyle = {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
    padding: "0",
    color: "white",
  };

  const buttonStyle = {
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#007BFF", // Blue color similar to the screenshot
    "&:hover": {
      backgroundColor: "#0056b3", // Darker blue on hover
    },
    textTransform: "none", // Disable uppercase transformation
    borderRadius: "5px",
    padding: "10px 0",
  };

  const historyTextStyle = {
    color: "white",
    fontSize: "14px",
    textAlign: "left",
  };

  return (
    <Box
      sx={{
        borderRadius: "8px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        padding: "20px",
        backgroundColor: "#E0E0E0",
      }}
    >
      <Box
        sx={{
          height: "auto",
          width: "100%",
          backgroundColor: "#2B2B2B",
          padding: "10px",
          marginBottom: "20px",
          borderRadius: "5px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            color: "white",
            marginBottom: "10px",
          }}
        >
          History
        </Typography>
        <Box
          sx={{
            backgroundColor: "#1565C0",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Typography sx={historyTextStyle}>
            14.25.31 - Reference{" "}
            <span style={{ fontWeight: "bold" }}>TCF32159645878</span> has been
            created
          </Typography>
          <Typography sx={historyTextStyle}>
            14.29.11 - Meals requested
          </Typography>
          <Typography sx={historyTextStyle}>
            14.33.47 - Meals approved
          </Typography>
          <Typography sx={historyTextStyle}>
            14.39.01 - Docs uploaded{" "}
            <span style={{ fontWeight: "bold" }}>p-mahmud.jpg</span>{" "}
            successfully.
          </Typography>
          <Typography sx={historyTextStyle}>
            14.49.51 - Docs uploaded{" "}
            <span style={{ fontWeight: "bold" }}>p-mahmud-visa.jpg</span>{" "}
            successfully.
          </Typography>
        </Box>
      </Box>

      <Box style={boxStyle}>
        <Button style={buttonStyle} fullWidth variant="contained">
          Pay now
        </Button>
      </Box>
      <Box style={boxStyle}>
        <Button
          style={buttonStyle}
          fullWidth
          variant="contained"
          onClick={() => handleDownloadPDF()}
        >
          Print & download
        </Button>
      </Box>
      <Box style={boxStyle}>
        <Button style={buttonStyle} fullWidth variant="contained">
          Ancillary Services
        </Button>
      </Box>
      <Box style={boxStyle}>
        <Button style={buttonStyle} fullWidth variant="contained">
          Upload docs
        </Button>
      </Box>
      <Box style={boxStyle}>
        <Button
          style={{
            ...buttonStyle,
            backgroundColor: "#D32F2F",
            "&:hover": { backgroundColor: "#B71C1C" },
          }}
          fullWidth
          variant="contained"
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default BookingOptions;
