import React from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAirPriceData } from "../../redux/slices/airPriceSlice";

const AirPriceShow = () => {
  const airPriceData = useSelector(selectAirPriceData);
  console.log(airPriceData);
  const offer = airPriceData?.response?.offersGroup?.[0]?.offer;
  const fareDetail = offer?.fareDetailList?.[0]?.fareDetail;
  console.log(fareDetail);
  // Function to calculate the total fare
  const calculateTotalFare = () => {
    if (fareDetail) {
      return (
        fareDetail.baseFare +
        fareDetail.tax +
        fareDetail.otherFee +
        fareDetail.vat -
        fareDetail.discount
      );
    }
    return 0;
  };

  const boxStyle = {
    width: "100%",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid white", // Add any additional styling you need
  };

  const customStyle = {
    fontWeight: "bold",
  };

  return (
    <Box
      sx={{ padding: "10px", borderRadius: "5px", border: "1px solid white" }}
    >
      <Box
        sx={{
          height: "50px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      />

      {fareDetail && (
        <>
          <Box style={boxStyle}>
            <Typography>Base fare</Typography>
            <Typography style={customStyle}>{fareDetail.baseFare}</Typography>
          </Box>
          <Box style={boxStyle}>
            <Typography>Taxes</Typography>
            <Typography style={customStyle}>{fareDetail.tax}</Typography>
          </Box>
          <Box style={boxStyle}>
            <Typography>AIT & VAT</Typography>
            <Typography style={customStyle}>{fareDetail.vat}</Typography>
          </Box>
          <Box style={boxStyle}>
            <Typography>Other Charge & fees</Typography>
            <Typography style={customStyle}>{fareDetail.otherFee}</Typography>
          </Box>
          <Box style={boxStyle}>
            <Typography>Discount</Typography>
            <Typography style={customStyle}>{fareDetail.discount}</Typography>
          </Box>

          <Box style={boxStyle}>
            <Typography>Grand Total</Typography>
            <Typography style={customStyle}>{calculateTotalFare()}</Typography>
          </Box>
        </>
      )}

      {/* Promo code box */}
      <Box
        sx={{
          height: "80px",
          backgroundColor: "primary.main",
          padding: "10px",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "flex-start", color: "white" }}
        >
          <Typography>Apply promo</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            InputProps={{
              sx: {
                "& .MuiInputBase-input::placeholder": {
                  color: "white", // Change this to your desired placeholder color
                },
              },
            }}
            variant="standard"
            placeholder="Enter your promo code"
          />
          <Button sx={{ backgroundColor: "white" }} variant="contained">
            <Typography sx={{ color: "primary.main" }}>Apply</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AirPriceShow;
