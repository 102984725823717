// api.js

// const BASE_URL = 'http://localhost:5000/api'; // Your API base URL
const BASE_URL = process.env.REACT_APP_API_URL;
// Function to fetch airports based on the search query
export const fetchAirports = async (searchQuery) => {
  try {
    console.log(`Fetching airport data with query: ${searchQuery}`);
    const response = await fetch(
      `${BASE_URL}/api/airports/airportList?query=${searchQuery}`
    );
    if (response.ok) {
      const airportData = await response.json();
      console.log("Fetched airport data:", airportData);
      return airportData;
    } else {
      const errorText = await response.text(); // Get error text if available
      console.error("Failed to fetch airport data:", errorText);
      throw new Error(`Failed to fetch airport data: ${response.status}`);
    }
  } catch (error) {
    console.error(`Error fetching airport data: ${error.message}`);
    throw new Error(`Error fetching airport data: ${error.message}`);
  }
};

// Add more API functions as needed
export default fetchAirports;
