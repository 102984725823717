import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FromAirportPopover from "./FromAirportPopover";
import ToAirportPopover from "./ToAirportPopover";
import TravelDatePopover from "./TravelDatePopover";
import ReturnDatePopover from "./ReturnDatePopover";
import TravelerClassPopover from "./TravelerClassPopover";
import { selectFlightSearchParams } from "../../redux/reducers/flightSlice";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { selectFlightSearchData } from "../../redux/reducers/flightSlice";
import useStyles, {
  swapIconStyle,
  boxStyle,
  paperStyle,
  typographyPadding,
} from "./styles";
import fetchAirports from "../../services/api";
const AirInput = ({
  isFirstChild,
  onAddCity,
  onRemoveCity,
  segment,
  journeyType,
  updateSegment,
  selectedFromAirport,
  selectedToAirport,
  // handlePopoverClick,
  // fromAnchorEl,
  // toAnchorEl,
  // handlePopoverClose,
  // handleSearchQueryChange,
  // searchQuery,
  // searchedAirports,
  // handleFromAirportSelect,
  // handleToAirportSelect,
  // handleDPopoverClick,
  // dAnchorEl,
  // handleDPopoverClose,
  // selectedDate,
  // handleSwapAirports,
  // dayOfWeek,
  // handleDepartureDateChange,
  handleRPopoverClick,
  returnAnchorEl,
  handleRPopoverClose,
  returnDate,
  handleReturnDateChange,
  openModal,
  isModalOpen,
  closeModal,
  adults,
  children,
  infants,
  selectedClass,
  handleClassChange,
  classes,
  travelerCount,
  // isTravelDatePopoverOpen,
  // isReturnDatePopoverOpen,
}) => {
  const travelDateRef = useRef(null);
  const searchParams = useSelector(selectFlightSearchParams);
  const styles = useStyles();
  const flightdata = useSelector(selectFlightSearchData);
  console.log(flightdata);
  const { id, fromAirport, toAirport, date } = segment;

  // Local state variables
  const [fromAnchorEl, setFromAnchorEl] = useState(null);
  const [toAnchorEl, setToAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedAirports, setSearchedAirports] = useState([]);
  const [selectedDate, setSelectedDate] = useState(date || dayjs());
  const [dayOfWeek, setDayOfWeek] = useState(selectedDate.format("dddd"));
  const [dAnchorEl, setDanchorEl] = useState(null);
  const [isTravelDatePopoverOpen, setIsTravelDatePopoverOpen] = useState(false);

  // Handlers for airport selection
  const handlePopoverClick = (event, anchor) => {
    setSearchQuery(""); // Reset search query
    if (anchor === "from") {
      setFromAnchorEl(event.currentTarget);
      setToAnchorEl(null);
    } else if (anchor === "to") {
      setToAnchorEl(event.currentTarget);
      setFromAnchorEl(null);
    }
  };

  const handlePopoverClose = () => {
    setFromAnchorEl(null);
    setToAnchorEl(null);
    setSearchQuery(""); // Reset search query
  };

  const handleSearchQueryChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleFromAirportSelect = (airport) => {
    updateSegment({ ...segment, fromAirport: airport });
    handlePopoverClose();
  };

  const handleToAirportSelect = (airport) => {
    updateSegment({ ...segment, toAirport: airport });
    handlePopoverClose();
  };

  // Fetch airports based on search query
  const handleFetchAirports = async () => {
    try {
      const airportData = await fetchAirports(searchQuery);
      console.log("API response:", airportData);
      setSearchedAirports(airportData);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (!searchQuery) {
      setSearchedAirports([]);
      return;
    }
    handleFetchAirports();
  }, [searchQuery]);

  // Handle date changes
  const handleDepartureDateChange = (date) => {
    setSelectedDate(date);
    setDayOfWeek(date.format("dddd"));
    updateSegment({ ...segment, date: date });
    handleDPopoverClose();
  };

  const handleDPopoverClick = (event) => {
    setDanchorEl(event.currentTarget);
    setIsTravelDatePopoverOpen(true);
  };

  const handleDPopoverClose = () => {
    setDanchorEl(null);
    setIsTravelDatePopoverOpen(false);
  };

  // Optional: Handle swapping airports
  const handleSwapAirports = () => {
    updateSegment({
      ...segment,
      fromAirport: segment.toAirport,
      toAirport: segment.fromAirport,
    });
  };
  return (
    <Grid container spacing={1} style={{ paddingBottom: "60px", width: "99%" }}>
      <Grid item sm={12} xs={12} lg={6} md={6} direction="row">
        <Box style={{ ...paperStyle, position: "relative" }}>
          <Box
            onClick={(event) => handlePopoverClick(event, "from")}
            style={{
              ...boxStyle,
              borderRight: "1px solid #E5E7EB",
              borderRadius: "10px 0 0 10px",
              width: "calc(50% - 20px)",
              textAlign: "left",
              paddingLeft: "10px",
            }}
          >
            <Typography
              sx={{
                ...typographyPadding,
                fontFamily: "Google Sans, sans-serif",
                color: "#6C757D",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              From
            </Typography>
            <Typography
              style={{
                ...typographyPadding,
                fontWeight: "bold",
                fontSize: "1.25rem",
                fontFamily: "Google Sans, sans-serif",
                color: "#343A40",
                textAlign: "left",
              }}
            >
              {/* {selectedFromAirport
                ? selectedFromAirport.city
                : "Select an Airport"} */}
              {fromAirport ? fromAirport.city : "Select an Airport"}
            </Typography>
            <Typography
              style={{
                ...typographyPadding,
                fontSize: "13px",
                fontFamily: "Google Sans, sans-serif",
                color: "#6C757D",
                whiteSpace: "nowrap",
                textAlign: "left",
              }}
            >
              {/* {selectedFromAirport
                ? selectedFromAirport.name
                : "Select an Airport"} */}
              {fromAirport ? fromAirport.name : "Select an Airport"}
            </Typography>
          </Box>

          <Box onClick={handleSwapAirports} style={swapIconStyle}>
            <SwapHorizIcon style={{ fontSize: "24px", color: "#FF0000" }} />
          </Box>

          <FromAirportPopover
            fromAnchorEl={fromAnchorEl}
            handlePopoverClose={handlePopoverClose}
            searchQuery={searchQuery}
            handleSearchQueryChange={handleSearchQueryChange}
            handleFromAirportSelect={handleFromAirportSelect}
            searchedAirports={searchedAirports}
            classes={classes}
          />

          <Box
            onClick={(event) => handlePopoverClick(event, "to")}
            style={{
              ...boxStyle,
              borderRadius: "0 10px 10px 0",
              width: "calc(50% - 20px)",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                ...typographyPadding,
                fontFamily: "Google Sans, sans-serif",
                color: "#6C757D",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              To
            </Typography>
            <Typography
              style={{
                ...typographyPadding,
                fontWeight: "bold",
                fontSize: "1.25rem",
                fontFamily: "Google Sans, sans-serif",
                color: "#343A40",
                textAlign: "left",
              }}
            >
              {/* {selectedToAirport ? selectedToAirport.city : "Select an Airport"} */}
              {toAirport ? toAirport.city : "Select an Airport"}
            </Typography>
            <Typography
              style={{
                ...typographyPadding,
                fontSize: "13px",
                fontFamily: "Google Sans, sans-serif",
                color: "#6C757D",
                whiteSpace: "nowrap",
                textAlign: "left",
              }}
            >
              {/* {selectedToAirport ? selectedToAirport.name : "Select an Airport"} */}

              {toAirport ? toAirport.name : "Select an Airport"}
            </Typography>
          </Box>

          <ToAirportPopover
            toAnchorEl={toAnchorEl}
            handlePopoverClose={handlePopoverClose}
            searchQuery={searchQuery}
            handleSearchQueryChange={handleSearchQueryChange}
            handleToAirportSelect={handleToAirportSelect}
            searchedAirports={searchedAirports}
            classes={classes}
          />
        </Box>
      </Grid>

      <Grid item sm={12} xs={12} lg={6} md={6}>
        <Box style={{ ...paperStyle, position: "relative" }}>
          <Box
            id="travelDateTrigger"
            ref={travelDateRef}
            onClick={handleDPopoverClick}
            style={{
              ...boxStyle,
              borderRight: "1px solid #E5E7EB",
              borderRadius: "10px 0 0 10px",
              width: "calc(33.33% - 20px)",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Google Sans, sans-serif",
                color: "#6C757D",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              Travel Date
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.25rem",
                fontFamily: "Google Sans, sans-serif",
                color: "#343A40",
                textAlign: "left",
              }}
            >
              {selectedDate.format("DD MMM YY")}
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                fontFamily: "Google Sans, sans-serif",
                color: "#6C757D",
                textAlign: "left",
              }}
            >
              {dayOfWeek}
            </Typography>
          </Box>

          <TravelDatePopover
            dAnchorEl={dAnchorEl}
            handleDPopoverClose={handleDPopoverClose}
            selectedDate={selectedDate}
            handleDepartureDateChange={handleDepartureDateChange}
            classes={classes}
          />

          <Box
            id="returnDateTrigger"
            onClick={(event) => handleRPopoverClick(event)}
            style={{
              ...boxStyle,
              borderRight: "1px solid #E5E7EB",
              width: "calc(33.33% - 20px)",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Google Sans, sans-serif",
                color: "#6C757D",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              Return Date
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.25rem",
                fontFamily: "Google Sans, sans-serif",
                color: "#343A40",
                textAlign: "left",
              }}
            >
              {returnDate ? returnDate.format("DD MMM YY") : "Tap to add"}
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                fontFamily: "Google Sans, sans-serif",
                color: "#6C757D",
                textAlign: "left",
              }}
            >
              {returnDate ? returnDate.format("dddd") : ""}
            </Typography>
          </Box>

          <ReturnDatePopover
            returnAnchorEl={returnAnchorEl}
            handleRPopoverClose={handleRPopoverClose}
            returnDate={returnDate}
            handleReturnDateChange={handleReturnDateChange}
            classes={classes}
          />

          <Box
            onClick={openModal}
            style={{
              ...boxStyle,
              borderRadius: "0 10px 10px 0",
              width: "calc(33.33% - 20px)",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Google Sans, sans-serif",
                color: "#6C757D",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              Traveller & Class
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.25rem",
                fontFamily: "Google Sans, sans-serif",
                color: "#343A40",
                textAlign: "left",
              }}
            >
              {`${adults + children + infants} Person${
                adults + children + infants > 1 ? "s" : ""
              }`}
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                fontFamily: "Google Sans, sans-serif",
                color: "#6C757D",
                textAlign: "left",
              }}
            >
              {selectedClass}
            </Typography>
          </Box>

          <TravelerClassPopover
            isModalOpen={isModalOpen}
            dAnchorEl={dAnchorEl}
            closeModal={closeModal}
            travelerCount={travelerCount}
            adults={adults}
            children={children}
            infants={infants}
            selectedClass={selectedClass}
            handleClassChange={handleClassChange}
            classes={classes}
          />
        </Box>
        {/* Conditional buttons for multi-city */}
        {journeyType === "multicity" && (
          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
            >
              {!isFirstChild && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => onRemoveCity(segment.id)}
                >
                  Remove City
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={onAddCity}
                sx={{ marginLeft: 2 }}
              >
                Add City
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AirInput;
