import React, { useEffect, useRef, useState } from "react";
import { Container, Button, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import jsPDF from "jspdf";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { selectSearchIDResultID } from "../../redux/slices/searchIDResultIDSlice";
import LayoutPage from "../../pages/LayoutPage";
import BookingOptions from "./BookingOptions";
import CityLogo from "../../assets/logos/CityLogo.png";
import Code from "../../assets/images/code1.png";
import FlightCard from "../FlightResults/FlightCard";
import { selectAirPreBookSearchData } from "../../redux/slices/airPreBookSlice";
import { selectPassengerDetails } from "../../redux/slices/passengerDetailsSlice";
import { selectAirPriceData } from "../../redux/slices/airPriceSlice";
import { selectFlightData } from "../../redux/slices/singleFlightDataSlice";
const AirBookForm = () => {
  const airBookedSearchData = useSelector(selectAirPreBookSearchData);
  const passengerDetailsData = useSelector(selectPassengerDetails);
  const { searchId, resultId } = useSelector(selectSearchIDResultID);

  console.log(
    `Trace ID (Result ID): ${resultId}, Offer ID (Search ID): ${searchId}`
  );
  const segments = useSelector(selectFlightData);
  const flightData = airBookedSearchData.response?.offersGroup?.[0]?.offer;
  console.log(passengerDetailsData);
  const offer = airBookedSearchData?.response?.offersGroup?.[0]?.offer || {};
  const paxSegment = offer?.paxSegmentList?.[0]?.paxSegment || {};
  const fareDetail = offer?.fareDetailList?.[0]?.fareDetail || {};
  const baggageAllowance =
    offer?.baggageAllowanceList?.[0]?.baggageAllowance || {};

  const carrierDesigCode = paxSegment?.marketingCarrierInfo?.carrierDesigCode;
  const cabinType = paxSegment?.cabinType;
  const rbd = paxSegment?.rbd;
  const flightNumber = paxSegment?.flightNumber;
  const equipment = paxSegment?.iatA_AircraftType?.iatA_AircraftTypeCode;
  const checkInAllowance = baggageAllowance?.checkIn?.[0]?.allowance;
  const cabinAllowance = baggageAllowance?.cabin?.[0]?.allowance;
  const airlinePNR = paxSegment?.airlinePNR;

  const givenName =
    passengerDetailsData?.request?.paxList?.[0]?.individual?.givenName;
  const surname =
    passengerDetailsData?.request?.paxList?.[0]?.individual?.surname;

  const airRulesData = ""; // Update this with appropriate rule details if needed
  const fares = offer?.fareDetailList?.[0]?.fareDetail || {};
  const discount = fareDetail?.discount || 0;
  const passenger = passengerDetailsData?.Passengers?.[0] || {};

  const [airlineLogoUrl, setAirlineLogoUrl] = useState(null);
  const printableContentRef = useRef(null);

  useEffect(() => {
    const fetchLogoUrl = async () => {
      if (carrierDesigCode) {
        try {
          const response = await axios.get(`/api/airline/${carrierDesigCode}`);
          setAirlineLogoUrl(response.data.logoUrl);
        } catch (error) {
          console.error("Error fetching airline logo:", error);
        }
      }
    };
    fetchLogoUrl();
  }, [carrierDesigCode]);

  const handleDownloadPDF = () => {
    const content = printableContentRef.current;
    if (content) {
      const pdf = new jsPDF();
      pdf.html(content, {
        callback: () => {
          pdf.save("airBookForm.pdf");
        },
      });
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const calculateSubtotal = () => {
    return (
      (fares?.baseFare || 0) +
      (fares?.tax || 0) +
      (fares?.otherFee || 0) -
      discount
    );
  };

  return (
    <LayoutPage>
      <div
        style={{
          display: "flex",
          height: "100%",

          padding: "15px",
        }}
      >
        <div
          ref={printableContentRef}
          id="printableContent"
          style={{ flex: "1 0 30%", display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              flex: "1",

              display: "flex",
              border: "1px solid white",
              borderRadius: "2px",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                width: "50%",
                justifyContent: "left",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography>
                Booking ID:{" "}
                {airBookedSearchData?.BookingID || "Data is not available"}
              </Typography>
              <Typography>
                Booked By: {givenName || "Data is not available"}{" "}
                {surname || "Data is not available"}
              </Typography>
              <Typography>Reserved Unit</Typography>
              <Typography>Booking status</Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontStyle: "" }}>
                Ticket price is determined on the date of sale and is not
                guaranteed until ticketing. Time limit may be changed. The
                actual time limit should be requested in the call centre. Kindly
                issue or cancel the PNR before the booking is automatically
                expired or cancelled by the Airlines to avoid Airlines penalty
                or ADM.
              </Typography>
            </Box>
          </div>

          <div style={{ flex: "1 0 70%" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "baseline",
                }}
              >
                <img src={CityLogo} alt="Logo" />
                <Typography variant="body2">thecityflyers.com</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginBottom: "15px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#2F2F2F",
                    flex: "1 0 40%",
                    height: "40%",
                    marginRight: "5px",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      color: "white",
                      alignItems: "baseline",
                      display: "flex",
                    }}
                  >
                    <Typography sx={{ padding: "3px" }}>
                      Travel Note:
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "auto",
                      width: "auto",
                      backgroundColor: "white",
                      marginTop: "5px",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      border: "3px solid #2F2F2F",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant="body2" align="left">
                      Check In counter will open before{" "}
                      <strong>1.30 hours of domestic</strong> and{" "}
                      <strong>3 hours of international</strong> flight
                      departure.
                    </Typography>
                    <Typography variant="body2" align="left">
                      Passenger reporting late for check-in may be refused to
                      board on flight. Please bring a valid{" "}
                      <strong>Photo ID.</strong>
                    </Typography>
                    <Typography variant="body2" align="left">
                      Check-in counter will be closed before{" "}
                      <strong>1.30 hours of domestic </strong>and{" "}
                      <strong>3 hours of international</strong> flight
                      departure.
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    flex: "1 0 20%",
                    height: "20%",
                    marginTop: "-47px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "baseline",
                    marginRight: "5px",
                  }}
                >
                  <img
                    src={airlineLogoUrl || "Data is not available"}
                    alt="Airline Logo"
                    width="150"
                    height="120"
                  />
                  <Typography sx={{ fontWeight: "bold" }}>
                    {cabinType} / {rbd || "Data is not available"}. {equipment}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {carrierDesigCode || "Data is not available"}-
                    {flightNumber || "Data is not available"} / ONEWAY
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    CHECKIN: {checkInAllowance || "Data is not available"}
                    /CABIN:
                    {cabinAllowance || "Data is not available"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    flex: "1 0 40%",
                    height: "40%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "baseline",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "50px" }}>
                    Booked
                  </Typography>
                  <img src={Code} alt="Logo" />
                  <Typography sx={{ fontWeight: "bold" }}>
                    e-TKT: <span style={{ color: "blue" }}>ON HOLD</span>
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    AIRLINE PNR:{" "}
                    <span style={{ color: "blue" }}>
                      {airlinePNR || "Data is not available"}
                    </span>
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#ED6C02",
                  color: "white",
                  height: "30px",
                  width: "auto",
                  padding: "5px",
                  display: "flex",
                  marginBottom: "15px",
                  borderRadius: "5px",
                }}
              >
                <WarningOutlinedIcon />
                <Typography align="left">
                  Your booking will expire in 18.23.03 hours. Kindly pay the
                  ticket before the given ticketing time limit.
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#2F2F2F",
                  flex: "1 0 40%",
                  height: "40%",
                  marginBottom: "15px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    color: "white",
                    alignItems: "baseline",
                    display: "flex",
                  }}
                >
                  <Typography sx={{ padding: "3px" }}>Passenger</Typography>
                </Box>
                <Box
                  sx={{
                    height: "auto",
                    width: "auto",
                    backgroundColor: "white",
                    marginTop: "5px",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    border: "3px solid #2F2F2F",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <Typography align="center">
                      <PermIdentityOutlinedIcon sx={{ color: "#0067FF" }} />{" "}
                      {passengerDetailsData?.request?.paxList?.[0]?.individual
                        ?.gender === "Male"
                        ? "Mr."
                        : "Mrs."}{" "}
                      {passengerDetailsData?.request?.paxList?.[0]?.individual
                        ?.givenName || "Data is not available"}{" "}
                      {passengerDetailsData?.request?.paxList?.[0]?.individual
                        ?.surname || "Data is not available"}
                    </Typography>
                    <Typography>
                      <CallOutlinedIcon />{" "}
                      {passengerDetailsData?.request?.contactInfo?.phone
                        ?.phoneNumber || "Data is not available"}
                    </Typography>
                    <Typography>
                      <BadgeOutlinedIcon sx={{ color: "#0067FF" }} />{" "}
                      {passengerDetailsData?.request?.paxList?.[0]?.individual
                        ?.nationality || "Data is not available"}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#2F2F2F",
                  flex: "1 0 40%",
                  height: "40%",
                  marginBottom: "15px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    color: "white",
                    alignItems: "baseline",
                    display: "flex",
                  }}
                >
                  <Typography sx={{ padding: "3px" }}>
                    Flight itinerary
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: "auto",
                    width: "auto",
                    backgroundColor: "white",
                    marginTop: "5px",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    border: "3px solid #2F2F2F",
                    borderRadius: "5px",
                  }}
                >
                  <FlightCard flightData={flightData} showActions={false} />
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#2F2F2F",
                  flex: "1 0 40%",
                  height: "40%",
                  marginBottom: "15px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    color: "white",
                    alignItems: "baseline",
                    display: "flex",
                  }}
                >
                  <Typography sx={{ padding: "3px" }}>
                    Passenger Details
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: "auto",
                    width: "auto",
                    backgroundColor: "white",
                    marginTop: "5px",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    border: "3px solid #2F2F2F",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  <Box>
                    <Typography align="left">
                      Name <br />
                      {passengerDetailsData?.request?.paxList?.[0]?.individual
                        ?.givenName || "Data is not available"}{" "}
                      {passengerDetailsData?.request?.paxList?.[0]?.individual
                        ?.surname || "Data is not available"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "70%",
                    }}
                  >
                    <Typography align="left">
                      Gender <br />
                      {passengerDetailsData?.request?.paxList?.[0]?.individual
                        ?.gender || "Data is not available"}
                    </Typography>
                    <Typography align="left">
                      Passenger Type <br />
                      {passengerDetailsData?.request?.paxList?.[0]?.ptc ||
                        "Data is not available"}
                    </Typography>
                    <Typography align="left">
                      Date of Birth <br />
                      {passengerDetailsData?.request?.paxList?.[0]?.individual
                        ?.birthdate || "Data is not available"}
                    </Typography>
                    <Typography align="left">
                      Passport Number <br />
                      {passengerDetailsData?.request?.paxList?.[0]?.individual
                        ?.identityDoc?.identityDocID || "Data is not available"}
                    </Typography>
                    <Typography align="left">
                      Docs Expiration <br />
                      {passengerDetailsData?.request?.paxList?.[0]?.individual
                        ?.identityDoc?.expiryDate || "Data is not available"}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#2F2F2F",
                  flex: "1 0 40%",
                  height: "40%",
                  justifyContent: "center",
                  alignContent: "center",
                  display: "flex",
                  marginBottom: "15px",
                  borderRadius: "5px",
                }}
              >
                <Box sx={{ width: "20%" }}>
                  <Box
                    sx={{
                      color: "white",
                      alignItems: "baseline",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ padding: "3px" }}>
                      Trip Segment
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "auto",
                      width: "auto",
                      backgroundColor: "white",
                      marginTop: "5px",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      border: "3px solid #2F2F2F",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography>
                      {paxSegment?.departure?.iatA_LocationCode ||
                        "Data is not available"}{" "}
                      To{" "}
                      {paxSegment?.arrival?.iatA_LocationCode ||
                        "Data is not available"}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ width: "40%" }}>
                  <Box
                    sx={{
                      color: "white",
                      alignItems: "baseline",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ padding: "3px" }}>
                      Date change fees
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "auto",
                      width: "auto",
                      backgroundColor: "white",
                      marginTop: "5px",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      border: "3px solid #2F2F2F",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>
                        More than 24 hours before departure
                      </Typography>
                      <Typography variant="body1">
                        {offer?.penalty?.exchangePenaltyList?.[0]
                          ?.penaltyInfoList?.[0]?.textInfoList?.[0]
                          ?.info?.[0] || "Data is not available"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>Within 24 hours of departure</Typography>
                      <Typography variant="body1">
                        {offer?.penalty?.exchangePenaltyList?.[0]
                          ?.penaltyInfoList?.[1]?.textInfoList?.[0]
                          ?.info?.[0] || "Data is not available"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>After flight departure</Typography>
                      <Typography variant="body1">
                        {offer?.penalty?.exchangePenaltyList?.[0]
                          ?.penaltyInfoList?.[2]?.textInfoList?.[0]
                          ?.info?.[0] || "Data is not available"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ width: "40%" }}>
                  <Box
                    sx={{
                      color: "white",
                      alignItems: "baseline",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ padding: "3px" }}>
                      Cancellation fees
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "auto",
                      width: "auto",
                      backgroundColor: "white",
                      marginTop: "5px",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      border: "3px solid #2F2F2F",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>
                        More than 24 hours before departure
                      </Typography>
                      <Typography variant="body1">
                        {offer?.penalty?.refundPenaltyList?.[0]
                          ?.penaltyInfoList?.[0]?.textInfoList?.[0]
                          ?.info?.[0] || "Data is not available"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>Within 24 hours of departure</Typography>
                      <Typography variant="body1">
                        {offer?.penalty?.refundPenaltyList?.[0]
                          ?.penaltyInfoList?.[1]?.textInfoList?.[0]
                          ?.info?.[0] || "Data is not available"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography>After flight departure</Typography>
                      <Typography variant="body1">
                        {offer?.penalty?.refundPenaltyList?.[0]
                          ?.penaltyInfoList?.[2]?.textInfoList?.[0]
                          ?.info?.[0] || "Data is not available"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#2F2F2F",
                  flex: "1 0 40%",
                  height: "40%",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    color: "white",
                    alignItems: "baseline",
                    display: "flex",
                  }}
                >
                  <Typography sx={{ padding: "3px" }}>Fare Details</Typography>
                </Box>
                <Box
                  sx={{
                    height: "auto",
                    width: "auto",
                    backgroundColor: "white",
                    marginTop: "5px",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    border: "3px solid #2F2F2F",
                    display: "flex",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography align="left">
                      Passenger Type
                      <br />
                      {fareDetail?.paxType || "Data is not available"}
                    </Typography>
                    <Typography align="left">
                      Base fare
                      <br />
                      {fareDetail?.baseFare || "Data is not available"}
                    </Typography>
                    <Typography align="left">
                      Taxes
                      <br />
                      {fareDetail?.tax || "Data is not available"}
                    </Typography>
                    <Typography align="left">
                      AIT & VAT
                      <br />
                      {fareDetail?.vat || "N/A"}
                    </Typography>
                    <Typography align="left">
                      Discount
                      <br />
                      {discount}
                    </Typography>
                    <Typography align="left">
                      Other Charges
                      <br />
                      {fareDetail?.otherFee || "Data is not available"}
                    </Typography>
                    <Typography align="left">
                      Sub Total
                      <br />
                      {calculateSubtotal()}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
        <div style={{ flex: "0 0 20%" }}>
          <BookingOptions handleDownloadPDF={handleDownloadPDF} />
        </div>
      </div>
    </LayoutPage>
  );
};

export default AirBookForm;
