import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

const airPreBookSlice = createSlice({
  name: "airPreBook",
  initialState: {
    searchData: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    setAirPreBookSearchData: (state, action) => {
      state.searchData = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setAirPreBookSearchData, setLoading, setError } =
  airPreBookSlice.actions;

export const fetchAirPreBookResults = (formData) => async (dispatch) => {
  try {
    dispatch(setLoading());

    console.log(
      "Request Payload (formData):",
      JSON.stringify(formData, null, 2)
    );

    const response = await axios.post(`${BASE_URL}/api/airPreBook`, formData);

    console.log("API Response:", response.data);

    dispatch(setAirPreBookSearchData(response.data));
  } catch (error) {
    console.error("Detailed Error:", error.response || error.message);

    const errorMessage = error.response
      ? `Server responded with status ${
          error.response.status
        }: ${JSON.stringify(error.response.data)}`
      : error.request
      ? "Network error: No response received."
      : `Unexpected error: ${error.message}`;

    dispatch(setError(errorMessage));
  }
};

export const selectAirPreBookSearchData = (state) =>
  state.airPreBook.searchData;

export default airPreBookSlice.reducer;
