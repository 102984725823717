import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchParams,
  fetchFlightResults,
  selectFlightSearchParams,
} from "../../redux/reducers/flightSlice";

const FilterByDate = ({ onDateSelect }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const currentSearchParams = useSelector(selectFlightSearchParams);

  const boxStyle = (isActive) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: isMobile ? "70px" : "100px", // Adjust width for mobile and desktop
    height: "50px",
    marginRight: "10px",
    textAlign: "center",
    borderRadius: "5px",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: isActive
      ? theme.palette.primary.main
      : "rgba(255,255,255,0.5)",
    color: isActive ? "white" : theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "white",
    },
    transition: "background-color 0.3s, color 0.3s",
  });

  const formatDate = (date) => {
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const [activeBox, setActiveBox] = useState(3); // Default to the middle box
  const [dates, setDates] = useState([]);

  // Effect to generate dates based on currentSearchParams and initialize active box
  useEffect(() => {
    if (currentSearchParams && currentSearchParams.request) {
      const departureDate = new Date(
        currentSearchParams.request.originDest[0].originDepRequest.date
      );
      departureDate.setHours(0, 0, 0, 0); // Normalize departure date

      const newDates = [];
      for (let i = -3; i <= 3; i++) {
        const date = new Date(departureDate);
        date.setDate(departureDate.getDate() + i);
        newDates.push(date);
      }

      setDates(newDates);
      setActiveBox(3); // Center box for initial load
    }
  }, [currentSearchParams]);

  // Handle box click and dispatch actions
  const handleBoxClick = (boxNumber) => {
    setActiveBox(boxNumber);
    const selectedDate = dates[boxNumber];
    onDateSelect(selectedDate);

    // Update the date in the originDepRequest based on your currentSearchParams structure
    const newSearchParams = {
      ...currentSearchParams,
      request: {
        ...currentSearchParams.request,
        originDest: [
          {
            ...currentSearchParams.request.originDest[0],
            originDepRequest: {
              ...currentSearchParams.request.originDest[0].originDepRequest,
              date: selectedDate.toISOString().split("T")[0], // Set the new date here
            },
          },
        ],
      },
    };

    // Dispatch the new search parameters and fetch flight results
    dispatch(setSearchParams(newSearchParams));
    dispatch(fetchFlightResults(newSearchParams));
  };

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -100, // Adjust this value based on your needs
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 100, // Adjust this value based on your needs
        behavior: "smooth",
      });
    }
  };

  // Effect to trigger fetching flight data when currentSearchParams changes
  useEffect(() => {
    if (currentSearchParams) {
      dispatch(fetchFlightResults(currentSearchParams));
    }
  }, [currentSearchParams, dispatch]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton
        onClick={() => {
          if (activeBox > 0) {
            scrollLeft();
            handleBoxClick(activeBox - 1);
          }
        }}
        disabled={activeBox === 0}
      >
        <ArrowBackIcon sx={{ color: "primary.main" }} />
      </IconButton>
      <Box
        ref={scrollRef}
        sx={{
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
          whiteSpace: "nowrap",
          padding: "5px",
          width: isMobile ? "100%" : "70%", // Full width on mobile, reduced on larger screens
        }}
      >
        {dates.map((date, index) => (
          <Box
            key={index}
            onClick={() => handleBoxClick(index)}
            sx={boxStyle(activeBox === index)}
          >
            <Typography variant="body2">{formatDate(date)}</Typography>
          </Box>
        ))}
      </Box>
      <IconButton
        onClick={() => {
          if (activeBox < dates.length - 1) {
            scrollRight();
            handleBoxClick(activeBox + 1);
          }
        }}
        disabled={activeBox === dates.length - 1}
      >
        <ArrowForwardIcon sx={{ color: "primary.main" }} />
      </IconButton>
    </Box>
  );
};

export default FilterByDate;
