import { createSlice } from "@reduxjs/toolkit";

const flightDataSlice = createSlice({
  name: "flightData",
  initialState: {
    data: null,
  },
  reducers: {
    setFlightData: (state, action) => {
      state.data = action.payload;
    },
    clearFlightData: (state) => {
      state.data = null;
    },
  },
});

export const { setFlightData, clearFlightData } = flightDataSlice.actions;

export const selectFlightData = (state) => state.flightData.data;

export default flightDataSlice.reducer;
