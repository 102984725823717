import React, { useEffect, useState, useRef } from "react";
import { Grid, Button, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchFlightResults,
  setFromAirport,
  setToAirport,
  selectFromAirport,
  selectToAirport,
  setSearchParams,
  selectFlightSearchParams,
} from "../../redux/reducers/flightSlice";
import fetchAirports from "../../services/api";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import AirInput from "./AirInput";
import "./style.css";
import "@fontsource/poppins";
import { CustomIconButton } from "./CustomIconButton";
import useStyles, { gridContainerStyle, paperStyle } from "./styles"; // Importing styles

export const SearchForm = ({ searchButtonLabel }) => {
  const [selectedOption, setSelectedOption] = useState("oneway");
  const selectedFromAirport = useSelector(selectFromAirport);
  const selectedToAirport = useSelector(selectToAirport);
  const [searchedAirports, setSearchedAirports] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromAnchorEl, setFromAnchorEl] = useState(null);
  const [toAnchorEl, setToAnchorEl] = useState(null);
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(dayjs().add(3, "day"));
  const [dayOfWeek, setDayOfWeek] = useState(selectedDate.format("dddd"));
  const [dAnchorEl, setDanchorEl] = useState(null);
  const [returnAnchorEl, setReturnAnchorEl] = useState(null);
  const [returnDayOfWeek, setReturnDayOfWeek] = useState("");
  const [returnDatePopoverOpen, setReturnDatePopoverOpen] = useState(false);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState("Economy");
  const dispatch = useDispatch();
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const [returnDate, setReturnDate] = useState(null);
  const [airInputs, setAirInputs] = useState([{ id: 1 }]);

  const [isTravelDatePopoverOpen, setIsTravelDatePopoverOpen] = useState(false);
  const [isReturnDatePopoverOpen, setIsReturnDatePopoverOpen] = useState(false);
  const searchParams = useSelector(selectFlightSearchParams);
  const [segments, setSegments] = useState([
    {
      id: 1,
      fromAirport: null,
      toAirport: null,
      date: dayjs().add(3, "day"),
    },
  ]);

  const handleTravelDateClick = () => {
    setIsTravelDatePopoverOpen(!isTravelDatePopoverOpen);
    handleDPopoverClick();
  };
  useEffect(() => {
    if (searchParams.AdultQuantity) {
      setAdults(searchParams.AdultQuantity);
    }
    if (searchParams.ChildQuantity) {
      setChildren(searchParams.ChildQuantity);
    }
    if (searchParams.InfantQuantity) {
      setInfants(searchParams.InfantQuantity);
    }
  }, [searchParams]);
  const travelerCount = (type, action) => {
    // Total passenger limit is 10
    if (action === "increment" && adults + children + infants < 10) {
      switch (type) {
        case "adults":
          if (adults < 9) setAdults(adults + 1); // Maximum of 9 adults
          break;
        case "children":
          if (children < 6) setChildren(children + 1); // Maximum of 6 children
          break;
        case "infants":
          if (infants < adults) setInfants(infants + 1); // Infants should not exceed adults
          break;
        default:
          break;
      }
    } else if (action === "decrement") {
      switch (type) {
        case "adults":
          // Decrement adults, but not below 1, and ensure infants do not exceed adults
          if (adults > 1) {
            setAdults(adults - 1);
            if (infants > adults - 1) setInfants(adults - 1);
          }
          break;
        case "children":
          // Decrement children, but not below 0
          if (children > 0) setChildren(children - 1);
          break;
        case "infants":
          // Decrement infants, but not below 0
          if (infants > 0) setInfants(infants - 1);
          break;
        default:
          break;
      }
    }
  };

  const handleSwapAirports = () => {
    dispatch(setFromAirport(selectedToAirport));
    dispatch(setToAirport(selectedFromAirport));
  };
  const handleAddCity = () => {
    const newId = segments.length + 1;
    setSegments([
      ...segments,
      { id: newId, fromAirport: null, toAirport: null, date: dayjs() },
    ]);
    setAirInputs([...airInputs, { id: newId }]);
  };

  const handleRemoveCity = (id) => {
    setSegments(segments.filter((segment) => segment.id !== id));
    setAirInputs(airInputs.filter((input) => input.id !== id));
  };

  const handleReturnDateChange = (date) => {
    setReturnAnchorEl(null);
    setReturnDate(date);
    const dayOfWeek = date.format("dddd");
    setReturnDayOfWeek(dayOfWeek);
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleRPopoverClose = () => {
    setReturnDatePopoverOpen(false);
    setReturnAnchorEl(null);
    setIsReturnDatePopoverOpen(false);
  };

  const handleDPopoverClose = () => {
    setDanchorEl(null);
    setIsTravelDatePopoverOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDepartureDateChange = (date) => {
    setSelectedDate(date);
    handleDPopoverClose();
    const updatedSearchParams = {
      ...searchParams,
      Segments: [
        {
          ...searchParams.Segments?.[0],
          DepartureDateTime: date.format("YYYY-MM-DD"),
        },
      ],
    };

    dispatch(setSearchParams(updatedSearchParams));
  };

  useEffect(() => {
    if (searchParams?.Segments?.[0]?.DepartureDateTime) {
      setSelectedDate(dayjs(searchParams.Segments[0].DepartureDateTime));
    }
  }, [searchParams]);

  const handleDPopoverClick = (event) => {
    setDanchorEl(event.currentTarget);
    setIsTravelDatePopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setFromAnchorEl(null);
    setToAnchorEl(null);
    setSearchQuery(""); // Reset search query
  };

  const handleFromAirportSelect = (airport) => {
    dispatch(setFromAirport(airport));
    handlePopoverClose();
    setTimeout(
      () => setToAnchorEl(document.getElementById("toAirportTrigger")),
      0
    );
  };

  const handleToAirportSelect = (airport) => {
    dispatch(setToAirport(airport));
    handlePopoverClose();
  };
  const handlePopoverClick = (event, anchor) => {
    setSearchQuery(""); // Reset search query
    if (anchor === "from") {
      setFromAnchorEl(event.currentTarget);
      setToAnchorEl(null);
    } else if (anchor === "to") {
      setToAnchorEl(event.currentTarget);
      setFromAnchorEl(null);
    }
  };

  const handleRPopoverClick = (event) => {
    setReturnDatePopoverOpen(true);
    setReturnAnchorEl(event.currentTarget);
    setSelectedOption("return");
    setIsReturnDatePopoverOpen(true);
  };

  const handleSearchQueryChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const open = Boolean(anchorEl);

  const [journeyType, setJourneyType] = useState("oneway");

  const handleOptionChange = (newValue) => {
    setJourneyType(newValue);
    setSelectedOption(newValue);

    // Reset segments based on trip type
    if (newValue === "multicity") {
      // Initialize with two empty segments for multi-city trips
      setSegments([
        {
          id: 1,
          fromAirport: null,
          toAirport: null,
          date: dayjs().add(3, "day"),
        },
        {
          id: 2,
          fromAirport: null,
          toAirport: null,
          date: dayjs().add(4, "day"),
        },
      ]);
    } else {
      // Reset to a single segment for one-way or two segments for return
      setSegments([
        {
          id: 1,
          fromAirport: selectedFromAirport,
          toAirport: selectedToAirport,
          date: selectedDate,
        },
      ]);
    }
  };

  const handleFetchAirports = async () => {
    try {
      const airportData = await fetchAirports(searchQuery);
      console.log("API response:", airportData);
      setSearchedAirports(airportData);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (!searchQuery) {
      setSearchedAirports([]);
      return;
    }
    handleFetchAirports();
  }, [searchQuery]);

  const handleFormData = async () => {
    // Validate that all required fields are filled
    if (
      (selectedOption === "return" && !returnDate) ||
      segments.some((seg) => !seg.fromAirport || !seg.toAirport || !seg.date)
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    // Define the originDest array based on the selected trip type
    let originDest = [];
    if (selectedOption === "oneway") {
      // For one-way trips, add a single segment
      originDest = [
        {
          originDepRequest: {
            iatA_LocationCode: segments[0].fromAirport.code,
            date: segments[0].date.format("YYYY-MM-DD"),
          },
          destArrivalRequest: {
            iatA_LocationCode: segments[0].toAirport.code,
          },
        },
      ];
    } else if (selectedOption === "return") {
      // For return trips, add the main and return segments
      originDest = [
        {
          originDepRequest: {
            iatA_LocationCode: segments[0].fromAirport.code,
            date: segments[0].date.format("YYYY-MM-DD"),
          },
          destArrivalRequest: {
            iatA_LocationCode: segments[0].toAirport.code,
          },
        },
        {
          originDepRequest: {
            iatA_LocationCode: segments[0].toAirport.code,
            date: returnDate.format("YYYY-MM-DD"),
          },
          destArrivalRequest: {
            iatA_LocationCode: segments[0].fromAirport.code,
          },
        },
      ];
    } else if (selectedOption === "multicity") {
      // For multi-city trips, loop through each segment in segments array
      originDest = segments.map((seg) => ({
        originDepRequest: {
          iatA_LocationCode: seg.fromAirport.code,
          date: seg.date.format("YYYY-MM-DD"),
        },
        destArrivalRequest: {
          iatA_LocationCode: seg.toAirport.code,
        },
      }));
    }

    // Create the main request object
    const updatedFormData = {
      pointOfSale: "BD",
      request: {
        originDest,
        pax: [],
        shoppingCriteria: {
          tripType: selectedOption === "multicity" ? "Circle" : selectedOption,
          travelPreferences: {
            vendorPref: [],
            cabinCode: selectedClass,
          },
          returnUPSellInfo: true,
        },
      },
    };

    // Add passenger details (ADT for adults, CHD for children, INF for infants)
    ["ADT", "CHD", "INF"].forEach((ptc) => {
      const count = ptc === "ADT" ? adults : ptc === "CHD" ? children : infants;
      for (let i = 0; i < count; i++) {
        updatedFormData.request.pax.push({
          paxID: `PAX${updatedFormData.request.pax.length + 1}`,
          ptc,
        });
      }
    });

    console.log("Submitting form data:", updatedFormData);

    // Dispatch the search action
    try {
      setIsFetching(true);
      const result = await dispatch(fetchFlightResults(updatedFormData));
      console.log("Search Results:", result);
      history.push("/flight-results");
    } catch (error) {
      console.error("Search Error:", error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      <Grid container style={gridContainerStyle}>
        <Grid item xs={12} style={{ marginLeft: "10px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start", // Aligns CustomIconButton to start from the left
              alignItems: "center",
              width: "100%",
            }}
            aria-label="journey-type"
            name="journey-type"
          >
            <CustomIconButton
              value="oneway"
              selectedValue={selectedOption}
              onChange={() => handleOptionChange("oneway")}
              Icon={KeyboardBackspaceIcon}
              label="One Way"
            />
            <CustomIconButton
              value="return"
              selectedValue={selectedOption}
              onChange={() => handleOptionChange("return")}
              Icon={RestartAltIcon}
              label="Return"
              rotate={true}
            />
            <CustomIconButton
              value="multicity"
              selectedValue={selectedOption}
              onChange={() => handleOptionChange("multicity")}
              Icon={CallSplitIcon}
              label="Multi City"
              rotate={true}
            />
          </Box>
        </Grid>

        {selectedOption === "multicity" ? (
          segments.map((segment, index) => (
            <AirInput
              key={segment.id}
              isFirstChild={index === 0}
              segment={segment}
              updateSegment={(updatedSegment) => {
                setSegments(
                  segments.map((seg) =>
                    seg.id === updatedSegment.id ? updatedSegment : seg
                  )
                );
              }}
              onAddCity={handleAddCity}
              onRemoveCity={() => handleRemoveCity(segment.id)}
              journeyType={journeyType}
              canRemove={airInputs.length > 1 && index !== 0}
              paperStyle={paperStyle}
              selectedFromAirport={selectedFromAirport}
              selectedToAirport={selectedToAirport}
              handlePopoverClick={handlePopoverClick}
              fromAnchorEl={fromAnchorEl}
              toAnchorEl={toAnchorEl}
              handlePopoverClose={handlePopoverClose}
              handleSearchQueryChange={handleSearchQueryChange}
              searchQuery={searchQuery}
              searchedAirports={searchedAirports}
              handleFromAirportSelect={handleFromAirportSelect}
              handleToAirportSelect={handleToAirportSelect}
              handleDPopoverClick={handleDPopoverClick}
              dAnchorEl={dAnchorEl}
              handleDPopoverClose={handleDPopoverClose}
              selectedDate={selectedDate}
              dayOfWeek={dayOfWeek}
              handleDepartureDateChange={handleDepartureDateChange}
              handleRPopoverClick={handleRPopoverClick}
              returnAnchorEl={returnAnchorEl}
              handleRPopoverClose={handleRPopoverClose}
              returnDate={returnDate}
              handleReturnDateChange={handleReturnDateChange}
              openModal={openModal}
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              adults={adults}
              children={children}
              infants={infants}
              selectedClass={selectedClass}
              handleClassChange={handleClassChange}
              classes={classes}
              setAdults={setAdults}
              setChildren={setChildren}
              setInfants={setInfants}
              isTravelDatePopoverOpen={isTravelDatePopoverOpen}
              isReturnDatePopoverOpen={isReturnDatePopoverOpen}
            />
          ))
        ) : (
          <AirInput
            key={airInputs[0].id}
            segment={segments[0]}
            updateSegment={(updatedSegment) => {
              setSegments([updatedSegment]);
            }}
            paperStyle={paperStyle}
            travelerCount={travelerCount}
            selectedFromAirport={selectedFromAirport}
            selectedToAirport={selectedToAirport}
            handlePopoverClick={handlePopoverClick}
            fromAnchorEl={fromAnchorEl}
            toAnchorEl={toAnchorEl}
            handlePopoverClose={handlePopoverClose}
            handleSearchQueryChange={handleSearchQueryChange}
            searchQuery={searchQuery}
            searchedAirports={searchedAirports}
            handleFromAirportSelect={handleFromAirportSelect}
            handleToAirportSelect={handleToAirportSelect}
            handleDPopoverClick={handleDPopoverClick}
            dAnchorEl={dAnchorEl}
            handleDPopoverClose={handleDPopoverClose}
            selectedDate={selectedDate}
            dayOfWeek={dayOfWeek}
            handleSwapAirports={handleSwapAirports}
            handleDepartureDateChange={handleDepartureDateChange}
            handleRPopoverClick={handleRPopoverClick}
            returnAnchorEl={returnAnchorEl}
            handleRPopoverClose={handleRPopoverClose}
            returnDate={returnDate}
            handleReturnDateChange={handleReturnDateChange}
            openModal={openModal}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            adults={adults}
            children={children}
            infants={infants}
            selectedClass={selectedClass}
            handleClassChange={handleClassChange}
            classes={classes}
            setAdults={setAdults}
            setChildren={setChildren}
            setInfants={setInfants}
            isTravelDatePopoverOpen={isTravelDatePopoverOpen}
            isReturnDatePopoverOpen={isReturnDatePopoverOpen}
          />
        )}
      </Grid>

      <Button
        onClick={handleFormData}
        variant="contained"
        color="primary"
        sx={{
          position: "relative",
          top: "-30px",
          zIndex: 1,
          width: "20%",
          height: "60px",
          textTransform: "capitalize",
          backgroundColor: "#0067FF",
          fontSize: "22px",
          "@media (max-width:600px)": {
            fontSize: "18px",
            width: "50%",
            height: "50px",
          },
        }}
      >
        {searchButtonLabel || "Search"}
      </Button>
    </>
  );
};

export default SearchForm;
