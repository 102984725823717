

import React, { useState } from 'react';

import LayoutPage from './LayoutPage';
import SearchFlight from '../components/FlightSearch/AirSearchForm';
const Homepage = () => {
  

  return (
    <LayoutPage>
      

      
      <SearchFlight/>
      
      
    </LayoutPage>
  );
};

export default Homepage;
