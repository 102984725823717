import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  Typography,
  Skeleton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import {
  ArrowForward as ArrowForwardIcon,
  AirlineSeatReclineNormal as AirlineSeatReclineNormalIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Flight as FlightIcon,
  Circle as CircleIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import TabComponent from "../tabComponent/TabComponent";
import { setFlightData } from "../../redux/slices/singleFlightDataSlice";
import FlightCardMobile from "./FlightCardMobile";
import "./FlightCard.css";
import { TabContext } from "@mui/lab";
import fetchAirports from "../../services/api";
import { selectFlightSearchData } from "../../redux/reducers/flightSlice";
import {
  fetchAirPrice,
  selectIsLoadingAirPrice,
} from "../../redux/slices/airPriceSlice";
import { setSearchIDResultID } from "../../redux/slices/searchIDResultIDSlice";
const BASE_URL = process.env.REACT_APP_API_URL;
export const FlightCard = React.memo(
  ({
    onFetchingStart,
    onFetchingComplete,
    flightData,
    onSelect,
    availability,
    showActions = true,
    isLoading,
    isReturn = false,
    hidePrice = false,
    returnFlightData,
  }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    console.log(returnFlightData);
    const isLoadingAirPrice = useSelector(selectIsLoadingAirPrice);
    const theme = useTheme();
    // console.log(isLoading);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [activeTab, setActiveTab] = useState(0);
    const [showDetails, setShowDetails] = useState(false);
    const [airlineLogoUrl, setAirlineLogoUrl] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    console.log(flightData);
    const [loading, setLoading] = useState(false); // New state for loading

    const allflightData = useSelector(selectFlightSearchData);

    const [departureInfo, setDepartureInfo] = useState({});
    const [arrivalInfo, setArrivalInfo] = useState({});
    const [airportInfo, setAirportInfo] = useState([]);

    // Use refs at the top level
    const prevDepartureCodeRef = useRef(null);
    const prevArrivalCodeRef = useRef(null);
    // Correctly extracting segments

    const segments =
      flightData?.paxSegmentList?.map((item) => item.paxSegment) || [];
    // console.log(segments);

    useEffect(() => {
      // Fetch logo and airport info for each segment
      const fetchDetails = async () => {
        try {
          // Fetch airline logo
          const airlineCode =
            segments[0]?.marketingCarrierInfo?.carrierDesigCode;
          const response = await axios.get(
            `${BASE_URL}/api/airline/${airlineCode}`
          );
          if (response?.data?.logoUrl) {
            setAirlineLogoUrl(response.data.logoUrl);
          }

          // Fetch airport details for each segment
          const airportPromises = segments.map(async (segment) => {
            const departureCode = segment?.departure?.iatA_LocationCode;
            const arrivalCode = segment?.arrival?.iatA_LocationCode;

            const [departureInfo, arrivalInfo] = await Promise.all([
              fetchAirports(departureCode),
              fetchAirports(arrivalCode),
            ]);

            return {
              departure: departureInfo[0] || {},
              arrival: arrivalInfo[0] || {},
            };
          });

          const airportData = await Promise.all(airportPromises);
          setAirportInfo(airportData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchDetails();
    }, [segments]);

    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleViewDetails = () => {
      if (segments.length === 0) return; // Prevent undefined access
      setShowDetails((prev) => !prev);
      setActiveTab("0");
    };

    const calculateTotalAmount = () => {
      let totalAmount = 0;

      // Check if fareDetailList is available and it is an array for outbound flight (OB)
      if (Array.isArray(flightData.fareDetailList)) {
        flightData.fareDetailList.forEach((fareDetail) => {
          // Extract the needed fields from each fareDetail
          const { baseFare, tax, otherFee, discount, vat, paxCount } =
            fareDetail.fareDetail;

          // Calculate the total for this fare detail and accumulate it
          totalAmount +=
            (baseFare + tax + otherFee - discount + vat) * paxCount;
        });
      } else {
        console.error("Fare details are not available for OB flight.");
      }

      // If IB (return flight) data is available, add its fare details
      if (Array.isArray(returnFlightData)) {
        returnFlightData.forEach((returnOffer) => {
          if (Array.isArray(returnOffer.offer.fareDetailList)) {
            returnOffer.offer.fareDetailList.forEach((fareDetail) => {
              const { baseFare, tax, otherFee, discount, vat, paxCount } =
                fareDetail.fareDetail;

              // Add the return flight cost to the total amount
              totalAmount +=
                (baseFare + tax + otherFee - discount + vat) * paxCount;
            });
          } else {
            console.error("Fare details are not available for IB flight.");
          }
        });
      }

      return totalAmount; // Return the combined fare amount of OB and IB flights
    };

    const calculateDuration = (segment) => {
      const durationInMinutes = parseInt(segment.duration, 10);

      const hours = Math.floor(durationInMinutes / 60);
      const minutes = durationInMinutes % 60;

      return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
    };
    // console.log(flightData);
    const handleSelect = async () => {
      setLoading(true); // Start loading when select is clicked
      try {
        console.log("Flight data in handleSelect:", flightData); // Log flightData to inspect it

        if (!flightData || !flightData.offerId) {
          throw new Error("Invalid flight data provided.");
        }

        const traceId = allflightData.response?.traceId;
        const offerId = flightData.offerId;

        // Dispatch the traceId and offerId to the Redux store
        dispatch(setSearchIDResultID({ searchId: traceId, resultId: offerId }));
        dispatch(setFlightData(flightData));
        const requestData = {
          traceId: allflightData.response.traceId,
          offerId: [flightData.offerId],
        };

        // Dispatch the fetchAirPrice action
        await dispatch(fetchAirPrice(requestData));

        // Redirect or handle other logic after dispatch if needed
        history.push("/airprebookform");
      } catch (error) {
        console.error("Error in handleSelect:", error.message);
      } finally {
        setLoading(false); // Stop loading after data is received
      }
    };

    useEffect(() => {
      const fetchAirportData = async () => {
        const departureCode =
          flightData?.paxSegmentList[0]?.paxSegment?.departure
            ?.iatA_LocationCode;
        const arrivalCode =
          flightData?.paxSegmentList[0]?.paxSegment?.arrival?.iatA_LocationCode;

        try {
          if (departureCode) {
            const departureInfo = await fetchAirports(departureCode);
            console.log("Departure airport info:", departureInfo);
            setDepartureInfo(departureInfo[0]); // Assuming the API returns an array
          }

          if (arrivalCode) {
            const arrivalInfo = await fetchAirports(arrivalCode);
            console.log("Arrival airport info:", arrivalInfo);
            setArrivalInfo(arrivalInfo[0]); // Assuming the API returns an array
          }
        } catch (error) {
          console.error("Error fetching airport information:", error);
        }
      };

      fetchAirportData();
    }, [flightData]);

    return (
      <>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <TabContext value={activeTab.toString()}>
          {isMobile ? (
            <FlightCardMobile
              flightData={flightData}
              calculateTotalAmount={calculateTotalAmount}
              calculateDuration={calculateDuration}
              handleSelect={handleSelect}
              showDetails={showDetails}
              handleViewDetails={handleViewDetails}
              airlineLogoUrl={airlineLogoUrl}
              isMobile={isMobile}
              segment={segments[0]}
              showActions={showActions}
            />
          ) : (
            <Box
              sx={{
                marginTop: "20px",
                boxShadow: showActions ? "4" : "none",
                borderRadius: "10px",
                paddingBottom: "1px",
              }}
              className="container"
            >
              {segments.map((segment, index) => (
                <React.Fragment key={index}>
                  <div className="flight-card">
                    <div className="grid-item logo">
                      {isLoading ? (
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={90}
                          height={90}
                        />
                      ) : (
                        airlineLogoUrl && (
                          <img
                            src={airlineLogoUrl}
                            alt="Airline Logo"
                            width="90"
                            height="90"
                          />
                        )
                      )}
                    </div>

                    <div className="grid-item flight-number">
                      <Box>
                        <Typography fontWeight="bold">
                          {isLoading ? (
                            <Skeleton animation="wave" width={30} />
                          ) : (
                            segment.marketingCarrierInfo
                              ?.marketingCarrierFlightNumber
                          )}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography fontWeight="bold">
                          {isLoading ? (
                            <Skeleton animation="wave" width={30} />
                          ) : (
                            segment.marketingCarrierInfo?.carrierDesigCode
                          )}
                        </Typography>
                        <Typography fontWeight="bold">
                          {isLoading ? (
                            <Skeleton animation="wave" width={30} />
                          ) : (
                            segment.iatA_AircraftType?.iatA_AircraftTypeCode
                          )}
                        </Typography>
                      </Box>
                    </div>

                    <div className="grid-item departure-city">
                      <Typography
                        fontSize="2rem"
                        color="#0067FF"
                        fontWeight="bold"
                        className="city-name"
                      >
                        {airportInfo[index]?.departure?.city || "Loading..."}
                      </Typography>
                      <Typography fontSize="1.5rem" className="city-code">
                        {segment.departure?.iatA_LocationCode || "Unknown Code"}
                      </Typography>
                    </div>
                    <div className="grid-item blank"></div>
                    <div className="grid-item arrival-city">
                      <Typography
                        fontSize="2rem"
                        color="#0067FF"
                        fontWeight="bold"
                      >
                        {airportInfo[index]?.arrival?.city || "Loading..."}{" "}
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                        fontSize="1.5rem"
                        mt="-15px"
                      >
                        {segment.arrival?.iatA_LocationCode || "Unknown Code"}
                      </Typography>
                    </div>
                    <div className="grid-item departure-time">
                      <Typography fontSize="3rem" fontWeight="bold">
                        {new Date(
                          segment.departure.aircraftScheduledDateTime
                        ).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </Typography>
                      <Typography
                        variant={isMobile ? "body2" : "h6"}
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          marginTop: "-15px",
                        }}
                      >
                        {new Date(
                          segment.departure.aircraftScheduledDateTime
                        ).toLocaleDateString("en-US", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })}
                      </Typography>
                    </div>

                    <div className="grid-item itinerary-icon">
                      <Box
                        style={{
                          flex: "1",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "80%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                paddingLeft: "3px",
                                width: "100%",
                              }}
                            >
                              <Divider
                                sx={{
                                  borderColor: "#0067FF",
                                  borderWidth: "1px",
                                  width: "30%",
                                }}
                              />
                              <Typography
                                style={{
                                  width: "40%",
                                  textAlign: "center",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  fontSize: "15px",
                                }}
                              >
                                {calculateDuration(segment)}
                              </Typography>
                              <Divider
                                sx={{
                                  borderColor: "#0067FF",
                                  borderWidth: "1px",
                                  width: "30%",
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "-15px !important",
                            }}
                          >
                            <FlightIcon
                              style={{
                                fontSize: isMobile ? "1rem" : "1.5rem",
                                color: "#0067FF",
                                transform: "rotate(90deg)",
                              }}
                            />
                            <Divider
                              sx={{
                                borderColor: "#0067FF",
                                borderWidth: "1px",
                                width: "100%",
                              }}
                            />
                            <CircleIcon
                              style={{
                                fontSize: isMobile ? "12px" : "12px",
                                color: "#0067FF",
                                marginLeft: 2,
                              }}
                            />
                          </Box>
                        </div>
                      </Box>
                    </div>

                    <div className="grid-item arrival-time">
                      <Typography fontSize="3rem" fontWeight="bold">
                        {new Date(
                          segment.arrival.aircraftScheduledDateTime
                        ).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </Typography>
                      <Typography
                        variant={isMobile ? "body2" : "h6"}
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          marginTop: "-15px",
                        }}
                      >
                        {new Date(
                          segment.arrival.aircraftScheduledDateTime
                        ).toLocaleDateString("en-US", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })}
                      </Typography>
                    </div>

                    {/* Additional Information */}
                    <div className="grid-item departure-airport">
                      {departureInfo.name || "Loading..."}
                    </div>
                    <div className="grid-item blank"></div>
                    <div className="grid-item arrival-airport">
                      {arrivalInfo.name || "Loading..."}
                    </div>

                    {index === 0 && (
                      <>
                        {!hidePrice && index === 0 && showActions && (
                          <div className="grid-item price">
                            <Typography fontSize="2rem" fontWeight="bold">
                              BDT {calculateTotalAmount(flightData)}
                            </Typography>
                          </div>
                        )}
                        <div className="grid-item seat-option">
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: "bold",
                              marginRight: 2,
                            }}
                          >
                            <AirlineSeatReclineNormalIcon
                              style={{
                                color: "#0067FF",
                                fontSize: "1.3rem",
                              }}
                            />
                            {availability}
                          </Typography>
                          <Button
                            sx={{
                              textOverflow: "ellipsis",
                              fontSize: "13px",
                              whiteSpace: "nowrap",
                              textAlign: "left",
                              color: "black",
                              textTransform: "none",
                              padding: 0,
                              "& .MuiSvgIcon-root": {
                                transition: "transform 0.3s",
                                transform: isMenuOpen
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              },
                            }}
                            onClick={handleMenuOpen}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Economy FL
                          </Button>

                          <Menu
                            anchorEl={anchorEl}
                            open={isMenuOpen}
                            onClose={handleMenuClose}
                          >
                            <MenuItem onClick={handleMenuClose}>
                              Demo 1
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose}>
                              Demo 2
                            </MenuItem>
                          </Menu>
                        </div>
                      </>
                    )}
                  </div>

                  {showActions && (
                    <Divider
                      variant="middle"
                      sx={{
                        my: 2,
                        width: "80%",
                        marginLeft: "-1px",
                        marginRight: "auto",
                      }}
                    />
                  )}
                </React.Fragment>
              ))}

              {!isReturn && showActions && (
                <>
                  <Divider
                    variant="middle"
                    sx={{
                      my: 2,
                      width: "80%",
                      marginLeft: "-1px",
                      marginRight: "auto",
                    }}
                  />

                  <div
                    className="button-container"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        color: "white",
                        backgroundColor: "#0067FF",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        ":hover": {
                          backgroundColor: "#00008B",
                        },
                      }}
                      onClick={handleViewDetails}
                      className="view-details-button"
                      style={{
                        width: "90%",
                        justifyContent: "flex-end",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "10px",
                      }}
                      endIcon={
                        <KeyboardArrowDownIcon
                          style={{
                            transform: showDetails
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.3s ease-in-out",
                          }}
                        />
                      }
                    >
                      {showDetails ? "Hide Details" : "View Details"}
                    </Button>

                    <Button
                      fullWidth
                      onClick={() => handleSelect(flightData)}
                      variant="contained"
                      color="primary"
                      className="select-button"
                      style={{
                        width: "20%",
                        justifyContent: "flex-end",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        ":hover": {
                          backgroundColor: "#00008B",
                        },
                      }}
                      endIcon={<ArrowForwardIcon />}
                    >
                      Select
                    </Button>
                  </div>
                </>
              )}

              {showDetails &&
                segments.length > 0 && ( // Check if segments are available
                  <TabComponent
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                    flightDataf={flightData}
                  />
                )}
            </Box>
          )}
        </TabContext>
      </>
    );
  }
);

export default FlightCard;
